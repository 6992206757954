import { FormikErrors } from 'formik';
import { MyBatchFormValue } from './types';
import { useLazyGetAirportByCodeQuery } from '../../../../../../API/airports';

export const validateBatchForm = async (
  values: MyBatchFormValue,
  setErrors?: (errors: FormikErrors<MyBatchFormValue>) => void,
  isDateBlendAvailable?: boolean,
  airportOptions?: any,
  isSecondAttempt?: boolean,
  shouldValidate?: boolean,
  getAirportByCode?: ReturnType<typeof useLazyGetAirportByCodeQuery>[0],
  setIsSecondAttempt?: (isSecondAttempt: boolean) => void
) => {
  const errors: Partial<MyBatchFormValue> = { batchInformation: [] };

  await Promise.all(
    values.batchInformation.map(async (batch, index) => {
      const batchErrors: Partial<typeof batch> = {};
      let isAirportMatched = false;

      if (getAirportByCode) {
        try {
          const { data } = await getAirportByCode(batch.fieldSaf, true);
          isAirportMatched = !!(data && data.code && data.name);
        } catch (error) {
          console.error('Airport lookup failed', error);
          isAirportMatched = false;
        }
      }

      if (!isAirportMatched && batch.fieldSaf && shouldValidate && !isSecondAttempt) {
        if (!isAirportMatched && batch.fieldSaf && batch.fieldBatchCountryOf) {
          batchErrors.fieldSaf = 'Airport not found';
        } else if (batch.fieldSaf && !batch.fieldBatchCountryOf) {
          batchErrors.fieldSaf = 'Country of end product delivery is required for this field';
        } else {
          batchErrors.fieldSaf = 'No airports available for the selected country';
        }
      }

      if (batch.fieldSaf && !batch.fieldBatchCountryOf) {
        batchErrors.fieldSaf = 'Country field is required for this field';
      }
      // @ts-ignore
      if (['Andorra', 'Liechtenstein', 'Monaco', 'Taiwan'].includes(batch.fieldBatchCountryOf) && !isSecondAttempt && batch.isExceptedCountry) {
        batchErrors.fieldSaf = 'Airport not found';
        setIsSecondAttempt && setIsSecondAttempt(true);
      }    

      if (!batch.fieldID) {
        batchErrors.fieldID = 'Field is required';
      }

      if (!batch.fieldTonnes) {
        batchErrors.fieldTonnes = 'Field is required';
      }

      if (!batch.fieldMJ) {
        batchErrors.fieldMJ = 'Field is required';
      }

      if (!batch.dateProd) {
        batchErrors.dateProd = 'Field is required';
      }

      if (!batch.dateBlend && isDateBlendAvailable) {
        batchErrors.dateBlend = 'Field is required';
      }

      if (!batch.entryBlendDate) {
        batchErrors.entryBlendDate = 'Field is required';
      }

      if (!batch.productDeliveryDate) {
        batchErrors.productDeliveryDate = 'Field is required';
      }

      if (!batch.fieldCO2) {
        batchErrors.fieldCO2 = 'Field is required';
      }

      if (!batch.fieldBatchCountryOf) {
        batchErrors.fieldBatchCountryOf = 'Field is required';
      }

      if (Object.keys(batchErrors).length > 0) {
        (errors.batchInformation as Array<Partial<typeof batch>>)[index] = batchErrors;
      }
    })
  );

  const fieldIDCounts = values.batchInformation.reduce((acc: any, batch: any) => {
    acc[batch.fieldID] = (acc[batch.fieldID] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  values.batchInformation.forEach((batch: any, index: any) => {
    if (fieldIDCounts[batch.fieldID] > 1) {
      (errors.batchInformation as Array<Partial<typeof batch>>)[index] = {
        ...((errors.batchInformation as Array<Partial<typeof batch>>)[index] || {}),
        fieldID: 'This batch ID must be unique',
      };
    }
  });

  setErrors && setErrors(errors);

  return errors;
};
