import React, { FC, useEffect, useMemo, useState } from 'react';
import './BatchFormStyles.scss';
import { batchInformation, IBatchFormProps } from './types';
import NextButton from '../../../../Buttons/NextButton/NextButton';
import Field from '../../Field/Field';
import FormTitle from '../../FormTitle/FormTitle';
import {
  COUNTRY,
  INCENTIVES_TAX_CREDITS,
} from '../../../../../dashboards/UserDashboard/BCURegistration/models/options.constant';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  handleEnterLatinAndNumbers,
  handleEnterNumbers,
} from '../../../../../../helpers/handleKeyPress';
import { useBatchForm } from './useBatchForm';
import { DefaultSelect } from '../../../../Form/DefaultSelect/DefaultSelect';
import {
  useLazyGetAirportByCodeQuery,
  useLazyGetAirportsQuery,
} from '../../../../../../API/airports';
import { toOptions } from '../../../../../../helpers/toOptions';
import { validateBatchForm } from './validateBatchForm';
import { BatchFormFields, removeAirportOption, updateBatchField } from '../../../../../../store/reducers/user/bcuFormReducer';
import { selectBaseLines, selectIncentives } from '../../../../../../store/reducers/user/abatementBaselinesReducer';
import { format } from 'date-fns';
import { CustomSelect } from '../../../../Form/CustomSelect/CustomSelect';
import convertToString from '../../../../../../helpers/convertToString';
import convertToNumber from '../../../../../../helpers/convertToNumber';

interface IBatchProps {
  onRemove: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFieldChange: (key: keyof batchInformation, value: any) => void;
  onChangeAirportMatch?: (state: boolean) => void;
  onChangeAirportOptions: (options: any) => void;
  batch: batchInformation;
  isDateBlendAvailable: boolean;
  index: number;
  errorVisible: boolean;
  showRSB: boolean;
  errors?: Record<keyof batchInformation, string>;
}

const Batch: FC<IBatchProps> = ({
  onRemove,
  onFieldChange,
  onBlur,
  onChangeAirportMatch,
  onChangeAirportOptions,
  batch,
  isDateBlendAvailable,
  index,
  errors,
  errorVisible,
  showRSB,
}) => {
  const dispatch = useAppDispatch();

  const [customSelectError, setCustomSelectError] = useState(false);
  const incentives = useAppSelector(selectIncentives);
  const incentivesOptions = useMemo(
    () =>
      (incentives || []).map(f => ({
        label: f.name,
        value: f.name,
      })),
    [incentives],
  );

  const isFirstInBatch = index === 0;
  const [getAirports] = useLazyGetAirportsQuery();
  const [getAirportByCode] = useLazyGetAirportByCodeQuery();
  const [selectedAirport, setSelectedAirport] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const baselines = useAppSelector(selectBaseLines);
  const formValues = useAppSelector(state => state.form);
  const selectedBaseline = (baselines || []).find(
    b =>
      b.id === formValues?.fields?.sustainabilityCertification ||
      b.name === formValues?.fields?.sustainabilityCertification,
  );
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 50;

  const [airportOptions, setAirportOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [isLoadingAirports, setIsLoadingAirports] = useState(true);

  const maxBCURegistrationDate = new Date();

  const onFieldDecChange = (options: any) => {
    const value = options.map((item: any) => item.value).join(', ');

    onFieldChange('fieldDec', value);
  };

  useEffect(() => {
    const baselineValue = selectedBaseline
      ? selectedBaseline.baselineValue
      : undefined;
    
    if (baselineValue !== undefined) {
      const convertedValue = convertToNumber(batch.fieldCO2);
      const fieldGHGValue = convertToString(
        (100 - (100 * convertedValue) / baselineValue).toLocaleString(
          undefined,
          {
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
          },
        ),
      );
      dispatch(
        updateBatchField({
          batchIndex: index,
          fieldName: 'fieldGHG',
          value: fieldGHGValue,
        }),
      );
      onFieldChange('fieldGHG', fieldGHGValue);
    }
  }, [])

  useEffect(() => {
    if (!batch.fieldBatchCountryOf) {
        setIsLoadingAirports(false);
        return;
    }

    setPage(0);
    setHasMore(true);

    getAirports({ country: batch.fieldBatchCountryOf, limit, offset: 0 }, true).then(({ data }) => {
        const transformedOptions = (data || []).map(({ code, name }: { code: string; name: string }) => ({
            value: code,
            label: `${name} [${code}]`,
        }));

        setIsLoadingAirports(false);
        setAirportOptions(transformedOptions);
        setHasMore(data.length === limit);
        onChangeAirportOptions(transformedOptions);
    });
  }, [batch.fieldBatchCountryOf]);

  const loadMoreAirports = () => {
    if (!hasMore) return;

    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoadingAirports(true);

    getAirports({ country: batch.fieldBatchCountryOf, limit, offset: nextPage * limit }, true).then(({ data }) => {
        const newOptions = (data || []).map(({ code, name }: { code: string; name: string }) => ({
            value: code,
            label: `${name} [${code}]`,
        }));

        setIsLoadingAirports(false);
        setAirportOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setHasMore(data.length === limit);
    });
  };

  useEffect(() => {
    if (!batch.fieldSaf || !batch.fieldBatchCountryOf) {
      setSelectedAirport(null);
      return;
    }

    getAirportByCode(batch.fieldSaf, true).then(({ data }) => {
      if (data && data.code && data.name) {
        if (onChangeAirportMatch) {
          onChangeAirportMatch(true);
        }
        setSelectedAirport({
          value: data.code,
          label: `${data.name} [${data.code}]`,
        });
      } else {
        if (onChangeAirportMatch) {
          onChangeAirportMatch(false);
        }
        dispatch(removeAirportOption());
      }
    });
  }, [batch.fieldBatchCountryOf, batch.fieldSaf]);

  const onChangeSelectErrorState = (state: boolean) => {
    setCustomSelectError(state);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="batch-form--wrapper mb-5">
      <FormTitle
        title={
          index > 0
            ? `Batch ${index + 1} Information`
            : `4. Batch 1 Information`
        }
        handleRemoveBatch={() => onRemove()}
        index={index}
      />
      <Field
        required
        type="fieldID"
        label="Batch ID end product"
        placeholder="Batch ID end product"
        id="fieldID"
        value={batch.fieldID}
        onChange={e => onFieldChange('fieldID', e.target.value)}
        onBlur={onBlur}
        errorVisible={errorVisible}
        error={errors?.fieldID}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        type="string"
        required
        label="Mass of Product (t)"
        placeholder="Mass of Product (t)"
        id="fieldTonnes"
        value={batch.fieldTonnes}
        onChange={e => onFieldChange('fieldTonnes', e.target.value)}
        onBlur={onBlur}
        error={errors?.fieldTonnes}
        errorVisible={errorVisible}
        formatOnBlur
        decimals={5}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        required
        type="string"
        label="Energy Content of Product (MJ)"
        placeholder="Energy Content of Product (MJ)"
        id="fieldMJ"
        value={batch.fieldMJ}
        onChange={e => onFieldChange('fieldMJ', e.target.value)}
        onBlur={onBlur}
        error={errors?.fieldMJ}
        formatOnBlur
        decimals={0}
        errorVisible={errorVisible}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        label="Date of production"
        placeholder="Date of production"
        required
        type="date"
        id="dateProd"
        onChange={e => onFieldChange('dateProd', e.target.value)}
        value={batch.dateProd}
        max={format(new Date(), 'yyyy-MM-dd')}
        onBlur={onBlur}
        error={errors?.dateProd}
        errorVisible={errorVisible}
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        label="Date of entry in tracking system"
        placeholder="Date of entry in tracking system"
        required
        type="date"
        id="entryBlendDate"
        onChange={e => onFieldChange('entryBlendDate', e.target.value)}
        value={batch.entryBlendDate}
        max={format(new Date(), 'yyyy-MM-dd')}
        onBlur={onBlur}
        error={errors?.entryBlendDate}
        errorVisible={errorVisible}
      />
      <p className="generalStep-info">
        Date product was entered in the user’s chain of custody tracking and management system.
      </p>

      {isDateBlendAvailable && (
        <Field
          handleKeyPress={handleEnterNumbers}
          label="Date of blending"
          placeholder="Date of blending"
          required
          type="date"
          id="dateBlend"
          onChange={e => onFieldChange('dateBlend', e.target.value)}
          value={batch.dateBlend || ''}
          max={format(new Date(), 'yyyy-MM-dd')}
          onBlur={onBlur}
          error={errors?.dateBlend}
          errorVisible={errorVisible}
        />
      )}

      <Field
        handleKeyPress={handleEnterNumbers}
        required
        label={
          <span>
            LCA GHG value [gCO<sub>2</sub>eq/MJ]
          </span>
        }
        placeholder="LCA GHG value [gCO2eq/MJ]"
        id="fieldCO2"
        onChange={e => onFieldChange('fieldCO2', e.target.value)}
        onBlur={onBlur}
        value={batch.fieldCO2}
        error={errors?.fieldCO2}
        errorVisible={errorVisible}
        formatOnBlur
        decimals={2}
      />
      <Field
        disabled
        label="GHG Emission Reduction [%]"
        id="fieldGHG"
        onChange={e => onFieldChange('fieldGHG', e.target.value)}
        onBlur={onBlur}
        value={batch.fieldGHG}
        error={errors?.fieldGHG}
        errorVisible={errorVisible}
        placeholder="0"
      />
      <Field
        handleKeyPress={handleEnterNumbers}
        label="Date of end product delivery to buyer, or PoS issue date if not yet delivered"
        placeholder="Date of end product delivery to buyer, or PoS issue date if not yet delivered"
        required
        type="date"
        id="productDeliveryDate"
        onChange={e => onFieldChange('productDeliveryDate', e.target.value)}
        value={batch.productDeliveryDate}
        max={format(maxBCURegistrationDate, 'yyyy-MM-dd')}
        onBlur={onBlur}
        error={errors?.productDeliveryDate}
        errorVisible={errorVisible}
      />
      <DefaultSelect
        required
        isSearchable
        inputId="fieldCountryOf"
        name="fieldCountryOf"
        label="Country of end product delivery"
        placeholder="Country of end product delivery"
        error={errors?.fieldBatchCountryOf || ''}
        options={COUNTRY as any}
        onBlur={onBlur}
        isDisabled={!isFirstInBatch}
        isErrorText={errorVisible}
        value={COUNTRY.find(c => c.value === batch.fieldBatchCountryOf) || null}
        onChange={(option: any) => {
          setSelectedAirport(null);
          onFieldChange('fieldSaf', '');
          onFieldChange('fieldBatchCountryOf', option.value);
        }}
      />
      <DefaultSelect
        isSearchable
        inputId="fieldSaf"
        name="fieldSaf"
        label="Airport of physical end product delivery"
        placeholder="Airport of physical end product delivery"
        isDisabled={!isFirstInBatch || !batch.fieldBatchCountryOf}
        error={errors?.fieldSaf || ''}
        options={airportOptions}
        value={selectedAirport}
        isErrorText={errorVisible}
        onBlur={onBlur}
        onChange={(option: any) => {
            onFieldChange('fieldSaf', option.value);
        }}
        isLoading={isLoadingAirports}
        onMenuScrollToBottom={loadMoreAirports}
      />
      {showRSB && (
        <Field
          handleKeyPress={handleEnterLatinAndNumbers}
          type="text"
          label="Additional claim as allowed under the RSB certification system"
          placeholder="Additional claim as allowed under the RSB certification system"
          id="fieldRsb"
          value={batch.fieldRsb}
          disabled={!isFirstInBatch}
          onChange={e => onFieldChange('fieldRsb', e.target.value)}
          onBlur={onBlur}
          error={errors?.fieldRsb}
          errorVisible={errorVisible}
        />
      )}
      <CustomSelect
        isMulti
        isForRegistration
        isSearchable
        inputId="fieldDec"
        name="fieldDec"
        label="Declaration of incentives and tax credits"
        placeholder="Declaration of incentives and tax credits"
        isDisabled={!isFirstInBatch}
        error={errors?.fieldDec || ''}
        isErrorText={errorVisible}
        // @ts-ignore
        options={incentivesOptions || []}
        value={batch.fieldDec?.split(',').map(option => option.trim())}
        onBlur={onBlur}
        onChange={(option: any) => {
          onFieldDecChange(option);
        }}
        onChangeSelectErrorState={onChangeSelectErrorState}
      />

      <p className="generalStep-info" style={ customSelectError ? { color: '#D64751'} : {}}>
        Manual entry length is 10 char MAX, 3 manual entries MAX.
      </p>
    </div>
  );
};

const BatchForm: FC<IBatchFormProps> = ({
  handleClick,
  setBatchNumber,
  batchNumber,
}) => {
  const formValues = useAppSelector(state => state.form);
  const sustainabilityCertification = useAppSelector(
    store => store.form.fields.sustainabilityCertification,
  );
  const baselines = useAppSelector(selectBaseLines);
  const selectedBaseline = (baselines || []).find(
    b =>
      b.id === sustainabilityCertification ||
      b.name === sustainabilityCertification,
  );
  const {
    formik,
    batchIndex,
    handleFieldChange,
    handleAddBatch,
    isRequiredFieldEmpty,
    handleRemoveBatch,
  } = useBatchForm(setBatchNumber);

  const [isSecondAttempt, setIsSecondAttempt] = useState(false);

  const isDateBlendAvailable = 
    formValues.fields?.endProduct === 'Blended' && 
    formValues.fields?.radioButton === 'Self-production';
    
  const [sharedData, setSharedData] = useState<
    Pick<
      BatchFormFields,
      'fieldBatchCountryOf' | 'fieldSaf' | 'fieldRsb' | 'fieldDec'
    >
  >({
    fieldBatchCountryOf: '',
    fieldDec: '',
    fieldRsb: '',
    fieldSaf: '',
  });

  useEffect(() => {
    if (formik.values.batchInformation.length === 0) {
      return;
    }

    const { fieldSaf, fieldDec, fieldRsb, fieldBatchCountryOf } =
      formik.values.batchInformation[0];

    setSharedData({
      fieldSaf,
      fieldRsb,
      fieldDec,
      fieldBatchCountryOf,
    });
  }, [formik.values.batchInformation]);

  const [errorVisible, setErrorVisible] = useState(false);
  const [isAirportMatched, setIsAirportMatched] = useState(false);
  const [airportOptions, setAirportOptions] = useState([]);

  const onChangeAirportMatch = (state: boolean) => {
    setIsAirportMatched(state);
  }

  const onChangeAirportOptions = (options: any) => {
    setAirportOptions(options);
  }

  const [getAirportByCode] = useLazyGetAirportByCodeQuery();
  
  const handleFormClick = async () => {
    const hasEmptyRequiredFields = isRequiredFieldEmpty();
    const isMatchingAirport2 = airportOptions.find(
      (airport: { value: string; label: string }) => airport.value === formik.values.batchInformation[0].fieldSaf
    );
    const validate = await validateBatchForm(
      {
        ...formik.values,
        batchInformation: formik.values.batchInformation.map((b, i) =>
          i > 0 ? { ...b, ...sharedData } : b,
        ),
      },
      formik.setErrors,
      isDateBlendAvailable,
      airportOptions,
      isSecondAttempt,
      formValues.shouldValidate,
      getAirportByCode,
      setIsSecondAttempt
    );

    const areErrors = Boolean(validate.batchInformation?.length);

    if (!isAirportMatched && airportOptions?.length) {
      setIsSecondAttempt(true);
    }

    if (isSecondAttempt && validate?.batchInformation?.[0]?.fieldSaf === 'No airports available for the selected country') {
      onChange(0, 'fieldSaf', '');
    }

    setErrorVisible(hasEmptyRequiredFields || areErrors);

    if (
      !hasEmptyRequiredFields && 
      (!areErrors 
        || ((!isMatchingAirport2 && 
          airportOptions?.length && 
          !formValues.batchForm[0].fieldSaf) 
        || isMatchingAirport2)
      )) {
      handleClick();
    }
  };

  const onChange = (
    index: number,
    key: keyof batchInformation,
    value: string,
  ) => {
    handleFieldChange(index, key, value);
    setErrorVisible(false);
  };

  const isCorsiaStep =
    selectedBaseline &&
    ['RSB CORSIA', 'RSB_CORSIA', 'ISCC CORSIA', 'ISCC_CORSIA'].indexOf(
      selectedBaseline.name,
    ) > -1;

  useEffect(() => {  
    if (formValues.shouldValidate) {
      formik.validateForm().then(() => {  
        handleFormClick();  
      });  
    }  
  }, [formValues.shouldValidate]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {formik.values.batchInformation.map((batch, index) => (
          <Batch
            key={index}
            batch={index > 0 ? { ...batch, ...sharedData } : batch}
            isDateBlendAvailable={isDateBlendAvailable}
            onChangeAirportOptions={onChangeAirportOptions}
            index={index}
            onBlur={formik.handleBlur}
            onRemove={() => handleRemoveBatch(index)}
            onFieldChange={(key, value) => onChange(index, key, value)}
            errors={
              formik.errors.batchInformation &&
              typeof formik.errors.batchInformation !== 'string'
                ? (formik.errors.batchInformation[index] as Record<
                    keyof batchInformation,
                    string
                  >)
                : undefined
            }
            errorVisible={errorVisible}
            showRSB={
              selectedBaseline &&
              selectedBaseline.name &&
              selectedBaseline.name.includes('RSB')
            }
            onChangeAirportMatch={onChangeAirportMatch}
          />
        ))}
        <div className="batch-form--btn">
          <NextButton
            handleClick={handleAddBatch}
            disabled={batchIndex > 18}
            text="Add another batch"
            styleText={{ color: '#07ACA6' }}
            style={{
              marginLeft: '0',
              width: '165px',
              background: '#FFFFFF',
              border: '1px solid #07ACA6',
            }}
          />
          <NextButton
            handleClick={handleFormClick}
            text={isCorsiaStep ? 'Next step' : 'Preview'}
            style={{ marginLeft: '223px' }}
          />
        </div>
      </form>
    </div>
  );
};

export default BatchForm;
