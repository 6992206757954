import { Button, notification, Radio, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { useCallback, useState, useMemo, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import {
  checkPoSNumberExist,
  downloadExcelTemplate,
} from '../../../../../../API/credits';

import PdfFileUpload from '../../PdfFileUpload/PdfFileUpload';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  clearFormFields,
  partialClearFormFields,
  setField,
  setFormValues,
  setShouldValidate,
  setUploadedExcel,
  setFileDataTwoStep,
  setFileUploadedTwoStep
} from '../../../../../../store/reducers/user/bcuFormReducer';

import { URL_EXCEL_TEMPLATE_PARSE } from '../../../../../../API/urls/urls';
import { actions } from '../../../../../../API/fetchProvider/fetch';
import { validate } from './validation';
import { useGetAbatementBaselinesQuery } from '../../../../../../API';
import { BCU_SOURCE } from '..';
import { mapParseData } from './mapper';
import { useLazyGetAirportByCodeQuery, useLazyGetAirportsQuery } from '../../../../../../API/airports';

import './ExcelSource.scss';

export enum NEAT_SOURCE {
  ACQUIRED = 'Acquired',
  PRODUCED = 'Produced',
}

type Props = {
  onFileUploadFirst: (file: File) => void;
  onDragAndDropFirst: (file: File | null) => void;
  onFileUploadSecond: (file: File) => void;
  onDragAndDropSecond: (file: File | null) => void;
  handleClick: () => void;
  setBCUSource: (source: BCU_SOURCE) => void;
};

const ExcelSource: React.FC<Props> = ({
  onFileUploadFirst,
  onDragAndDropFirst,
  onFileUploadSecond,
  onDragAndDropSecond,
  handleClick,
  setBCUSource,
}: Props) => {
  const dispatch = useDispatch();
  const formValues = useAppSelector(state => state.form);
  const [getAirports] = useLazyGetAirportsQuery();

  const [isRegistrationFileUploaded, setIsRegistrationFileUploaded] =
    useState(false);
  const [isPdfEndFileUploaded, setIsPdfEndFileUploaded] = useState(!!formValues.isFileUploaded || false);
  const [isPdfFileUploaded, setIsPdfFileUploaded] = useState(!!formValues.isFileUploadedTwoStep || false);
  const [neatSource, setNeatSource] = useState<NEAT_SOURCE>(
    formValues.fields?.radioButton === 'Supplier' 
      ? NEAT_SOURCE.ACQUIRED
      : NEAT_SOURCE.PRODUCED,
  );
  const [fileList, setFileList] = useState(formValues.uploadedExcel || []);
  const [airportOptions, setAirportOptions] = useState<
      { label: string; value: string }[]
    >([]);

  const { data } = useGetAbatementBaselinesQuery();

  const formik = useFormik({
    initialValues: formValues.fields,
    onSubmit: values => {},
  });

  useEffect(() => {
    if (!formValues.batchForm[0].fieldBatchCountryOf) {
      setAirportOptions([]);
      return;
    }

    getAirports({ country: formValues.batchForm[0].fieldBatchCountryOf }, true).then(
      ({ data }) => {
        const transformedOptions = (data || []).map(
          ({ code, name }: { code: string; name: string }) => ({
            value: code,
            label: `${name} [${code}]`,
          }),
        );

        setAirportOptions(transformedOptions);
      },
    );
  }, [formValues.batchForm[0].fieldBatchCountryOf]);

  const allFilesUploaded = useMemo(
    () =>
      neatSource === NEAT_SOURCE.ACQUIRED
        ? isPdfEndFileUploaded && isPdfFileUploaded
        : isPdfEndFileUploaded,
    [neatSource, isPdfEndFileUploaded, isPdfFileUploaded],
  );

  const handleChangeNeatSource = useCallback(
    (e: RadioChangeEvent) => {
      setNeatSource(e.target.value);
      dispatch(
        setField({
          fieldName: 'radioButton',
          value:
            e.target.value === NEAT_SOURCE.ACQUIRED
              ? 'Supplier'
              : 'Self-production',
        }),
      );
    },
    [setNeatSource],
  );

  const handleSetFieldValue = useCallback((name: string, value: any) => {
    dispatch(
      setField({
        fieldName: name as 'fieldUpDateProd' | 'fieldUpDate',
        value,
      }),
    );

    formik.setFieldValue(name, value);
  }, []);

  const handleUploadImage = useCallback(
    async (options: any) => {
      dispatch(clearFormFields());

      const { onSuccess, file } = options;
      const formData = new FormData();

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      formData.append('file', file);

      try {
        const res = await actions.post<AxiosResponse>(
          URL_EXCEL_TEMPLATE_PARSE,
          {
            payloads: formData,
            queries: { neatSource },
            ...config,
          },
        );

        const mappedData = mapParseData(res.data.data[0], data);

        const { fileData, fileDataTwoStep, isFileUploaded, isFileUploadedTwoStep } = formValues;

        const updatedMappedData = {
            ...mappedData,
            fileData,
            fileDataTwoStep,
            isFileUploaded,
            isFileUploadedTwoStep,
        };

        dispatch(setFormValues(updatedMappedData));
        dispatch(setShouldValidate(true));

        notification.success({ message: 'Successfully uploaded file' });
        setIsRegistrationFileUploaded(true);

        onSuccess('Ok');
      } catch (e: any) {
        notification.error({
          message: 'Error',
        });
      }
    },
    [formik, neatSource, dispatch, setFormValues, notification, actions],
  );
  
  const validatePoSNumber = useCallback(
    async (fieldPoSNumber: string) => {
      const { data: isPoSIdExist } = await dispatch<any>(
        checkPoSNumberExist(fieldPoSNumber),
      );
      return isPoSIdExist;
    },
    [checkPoSNumberExist],
  );

  const [getAirportByCode] = useLazyGetAirportByCodeQuery();

  const handleValidate = useCallback(async () => {
    const errors = await validate(formValues, validatePoSNumber, airportOptions, getAirportByCode);

    if (Object.keys(errors).length === 0) {
      handleClick();
    } else {
      setBCUSource(BCU_SOURCE.MANUAL);
      notification.warning({
        message: 'Found errors, please review field values in a manual form',
      });
    }
  }, [formValues, validatePoSNumber, handleClick, setBCUSource]);

  // OnFileUploadFirst should be refactored to a proper name as all the rest form values, dont have enought time for it :)))

  const handleUploadFirst = useCallback(
    (file: File) => {
      setIsPdfEndFileUploaded(true);
      onFileUploadFirst(file);
    },
    [setIsPdfEndFileUploaded, onFileUploadFirst],
  );

  const handleUploadSecond = useCallback(
    (file: File) => {
      setIsPdfFileUploaded(true);
      onFileUploadSecond(file);
    },
    [setIsPdfFileUploaded, onFileUploadSecond],
  );

  const templateName = useMemo(() => {
    return ` BCU_Registration_Template_${neatSource}.xlsx`;
  }, [neatSource]);

  const handleChange = ({ fileList }: { fileList: any }) => {
    setFileList(fileList);
    dispatch(setUploadedExcel(fileList));
  };

  const handleRemove = () => {
    setFileList([]);
    setIsRegistrationFileUploaded(false);
    dispatch(partialClearFormFields());
    dispatch(setShouldValidate(false));
  };

  useEffect(() => {
    if (neatSource === NEAT_SOURCE.PRODUCED) {
      dispatch(setFileDataTwoStep(''));
      dispatch(setFileUploadedTwoStep(false));
    }
  }, [neatSource]);
  
  return (
    <div className="excel-source-container">
      <div>
        <p>
          Product source <span style={{ color: 'red' }}>*</span>
        </p>
        <Radio.Group value={neatSource} onChange={handleChangeNeatSource}>
          <Radio value={NEAT_SOURCE.ACQUIRED || 'Supplier'} disabled={!!formValues.uploadedExcel.length}>Acquired</Radio>
          <Radio value={NEAT_SOURCE.PRODUCED || 'Self-production'} disabled={!!formValues.uploadedExcel.length}>Produced</Radio>
        </Radio.Group>
      </div>
      <p>Please, download the template: {templateName}</p>
      <div className="excel-source-template-container">
        <Button
          className="excel-source-template-btn"
          icon={<DownloadOutlined />}
          onClick={() => downloadExcelTemplate(neatSource)}>
          Download template
        </Button>
        <Upload
          multiple={false}
          maxCount={1}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          customRequest={handleUploadImage}
          showUploadList={true}
          fileList={fileList}
          onChange={handleChange}
          onRemove={handleRemove}  
        >
          <Button
            icon={<UploadOutlined />}
            className="excel-source-template-btn">
            Excel file upload
          </Button>
        </Upload>
      </div>
      <div>
        <PdfFileUpload
          setFieldValue={handleSetFieldValue}
          onDragAndDrop={onDragAndDropFirst}
          onFileUpload={handleUploadFirst}
          label="PoS pdf upload of end product"
          required
          id="fieldUpDateProd"
          name="fieldUpDateProd"
          error={formik.errors.fieldUpDate}
          // errorVisible={errorVisible}
        />

        {neatSource === 'Acquired' && (
          <PdfFileUpload
            setFieldValue={handleSetFieldValue}
            onDragAndDrop={onDragAndDropSecond}
            onFileUpload={handleUploadSecond}
            label="PoS pdf upload"
            required={neatSource === 'Acquired'}
            id="fieldUpDate"
            name="fieldUpDate"
            error={formik.errors.fieldUpDate}
          />
        )}
      </div>

      <button
        className="next-btn"
        onClick={handleValidate}
        disabled={!(allFilesUploaded && (isRegistrationFileUploaded || fileList.length > 0))}>
        Preview
      </button>
    </div>
  );
};

export default ExcelSource;
