import { actions } from './fetchProvider/fetch';
import {
    URL_BCU_AIRPORT,
    URL_BCU_AIRPORTS,
} from './urls/urls';
import { api, transformResponse } from './index';

export interface Airport {
    code: string;
    name: string;
    country: string;
}

export const airports = api.injectEndpoints({
    endpoints: (builder) => ({
        getAirports: builder.query<any, { search?: string; country?: string; limit?: number; offset?: number }>({
            keepUnusedDataFor: 120,
            providesTags: (result, e, args) => {
                const individualCache = (Array.isArray(result) ? result : []).map(
                    ({ code }: { code: string }) => ({ type: 'Airports' as const, id: code })
                );
                return [{ type: 'Airports' as const, search: args.search, country: args.country }, ...individualCache];
            },
            query: (queries) => ({
                action: () => actions.get(URL_BCU_AIRPORTS, { queries }),
            }),
            transformResponse,
        }),
        getAirportByCode: builder.query<any, string>({
            keepUnusedDataFor: 120,
            providesTags: (r, e, code) => [{ type: 'Airports', id: code }],
            query: (code) => ({
                action: () => actions.get(URL_BCU_AIRPORT.replace(':code', code)),
            }),
            transformResponse,
        })
    })
});

export const {
    useLazyGetAirportsQuery,
    useGetAirportsQuery,
    useGetAirportByCodeQuery,
    useLazyGetAirportByCodeQuery,
} = airports;
